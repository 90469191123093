<template>
  <div class="d-flex flex-wrap px-2">
    <div
      class="col-12 col-md-6 p-0"
      :class="{ 'order-md-0': index % 2 === 0, 'order-md-1': index % 2 !== 0 }"
    >
      <router-link :to="url" class="embed-responsive embed-responsive-4by3">
        <div class="embed-responsive-item">
          <img
            :src="require(`@/assets/item/${$t('items['+index+'].imageUrl')}`)"
            class="img-fluid prduct-img"
            alt=""
          />
        </div>
      </router-link>
    </div>
    <div
      class="col-12 col-md-6 p-0"
      :class="{ 'order-md-1': index % 2 === 0, 'order-md-0': index % 2 !== 0 }"
    >
      <div
        class="
          h-100
          d-flex
          flex-column
          justify-content-center
          align-items-center
          px-3
        "
        :class="{
          'text-md-left': index % 2 === 0,
          'text-md-right': index % 2 !== 0,
        }"
      >
        <h1 class="text-primary">{{ $t('items['+index+'].title') }}</h1>
        <div class="pl-3 d-flex flex-column">
          <span
            v-for="(item, index2) in detailTextArray"
            :key="index2"
            class="mb-1"
            >{{  $t('items['+index+'].detailTextArray['+index2+']') }}</span
          >
        </div>
        <div class="ml-5 pl-3 text-right">
          <router-link :to="url">{{$t('More')}}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch, computed, ref } from "vue";
// import { useI18n } from "vue-i18n";
export default {
  props: {
    title: String,
    imageUrl: String,
    url: String,
    detailTextArray: Array,
    index: Number,
  },
  setup(props) {
    // const { t, locale } = useI18n({
    //   inheritLocale: true,
    //   useScope: "locale",
    // });

    return {};
  },
};
</script>

<style lang="scss" scoped>
.prduct-img {
  transition: 0.6s;
  cursor: pointer;
}
.prduct-img:hover {
  transform: scale(1.2);
  transition-duration: 0.6s;
  cursor: pointer;
}
</style>