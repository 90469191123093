import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import "./scss/app2.scss";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

library.add(fas);

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

createApp(App).use(i18n).use(store).use(router).use(VueViewer).component("font-awesome-icon", FontAwesomeIcon).mount("#app");
