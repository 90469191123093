{
  "message": "hello i18n !!",
  "changeLocale": "選擇語言",
  "introduct": "產品介紹",
  "product1": {
    "title": "Nylon 6,6 is Your Best Choice.",
    "list1": "質輕強韌，比聚酯輕量15%以上，強韌性高20%，是訴求輕量織物的最佳選擇。",
    "list2": "抗起毬、抗撕裂效果較聚酯佳。",
    "list3": "抗磨耗效果較尼龍6以及聚酯佳。",
    "list4": "優異手感較尼龍6以及聚酯佳。",
    "list5": "水洗牢度較尼龍6以及聚酯佳。",
    "list6": "展現較低的縮率以及良好的回復性，尺寸安定性佳。",
    "list7": "優異化學穩定性，布料能承受如壓模、貼合、塗層等後加工處理。"
  },
  "product2": {
    "title": "尼龍6,6機能纖維",
    "list1": {
      "title": "Lightweight",
      "content": "Ultra-lite nylon 6,6 filaments with soft handle and strength are used to produce fabrics for lightweight downproof, packable jackets, fine-gauge knitting intimate apparel providing comfort of weightless. Sheer and see-through effects meet the requirement of second skin story."
    },
    "list2": {
      "title": "Super Hand Feel",
      "content": "Supermicro nylon 6,6 filaments with cutting-edge fineness are used to produce fabrics for downproof jacket, sleeping bag, outdoor wears and intimates delivering superior softness and excellent coverage effect of fabric."
    },
    "list3": {
      "title": "Strength & Durability",
      "content": "High tenacity nylon 6,6 filaments with exceptional robustness are used to produce fabrics for outdoor wears, jackets, performing excellent tearing, tensile strength, abrasion resistance with enhanced hand feel."
    },
    "list4": {
      "title": "Comfort Stretch",
      "content": "Innovative nylon 6,6 filaments with soft stretch property are used to produce woven stretch fabrics without the use of spandex providing lightweight, softness, color fastness and dimensional stability. Warp knit fabric made of superior textured yarn provides bulkiness, comfort, and stability of fabric."
    },
    "list5": {
      "title": "Style & Looks",
      "content": "A diverse range of fine filaments with full range of lustres, intrinsic strength, are used to produce fabrics for all end-users with superior soft handle, jewel-like lustre and luxurious aesthetics drape."
    },
    "list6": {
      "title": "Functions",
      "content": "Advanced nylon 6,6 filaments with inherent permanent functionalities including moisture management, thermal resistance, opacity & UV-protection are used to produce fabrics for intimates, active sports, outdoor wears and legwear."
    }
  },
  "product4": {
    "title": "原液染色尼龍6,6紗",
    "content1": "By adding color master batch to spinning process, solution dye make filament with excellent color shade and fastness and environmental-friendly as not necessary to dye it after weaving or knitting.",
    "content2": "Color Solution : 使用超亮光尼龍6,6原料，於紡絲工程中添加黑色無機顏料，使紡出的紗線一開始就具有良好的黑色色度，各種染色牢度都比 piece dye 優良，能克服 Piece dye 在日光牢度上不佳的問題。",
    "content3": "Softness Touch : 遠東先進所生產的原抽黑尼龍6,6纖維，dpf 可達到 1，且纖維纖度及物性均勻，手感及色相均 佳，可以直接作原絲使用與常規原絲作配列創造出布面立體外觀，亦可於ATY或DTY加工時與常規紗作交絡，再應用於平織或針織織造，可以創造特殊麻花外觀效果。",
    "content4": "It’s Green: 原液染色纖維可以減少後段染整過程中的化學染料助劑使用、廢水處理、有毒害物之物質產生等等 問題，可以有效減少能源消耗及CO2排放，具有明顯環保節能減碳及降低環境危害效益。"
  },
  "indexItem1": {
    "itemTitle": "舒彈",
    "itemSubtitle": "舒適伸展舒彈",
    "contentTitle1": "FEFC<sup>®</sup>彈力舒適尼龍6,6紗",
    "content1": "<p class=\"align-middle\">具有柔軟彈性的創新尼龍6,6長絲用於生產彈性面料，無需使用氨綸，提供輕質、柔軟、色牢度和尺寸穩定性。由優質變形紗線製成的針織面料，可提供織物蓬鬆度、舒適度和穩定性。</p>",
    "contentTitle2": "異收縮舒適彈尼龍6,6紗",
    "content2": "<p class=\"align-middle\">具柔軟彈性特性的創新尼龍6,6加工紗線，可用以製作平織彈性布，不須使用彈性纖維，並展現輕量、柔軟、高色牢度及高尺寸安定性等優點。針織布料採用此優異之加工紗，能展現豐厚、舒適及高穩定度之優點。</p>"
  },
  "indexItem2": {
    "itemTitle": "機能",
    "itemSubtitle": "",
    "contentTitle1": "尼龍6,6遠紅外線蓄熱保暖纖維",
    "content1": "<p class=\"align-middle\">於紡絲工程中加入經過特殊分散處理的遠紅外線吸收微粒粉體，此粉體可以吸收外界能量而促使織物釋放出特定遠紅外線波長，此特定波長可以振動活絡人體末梢血管，加速促進血液循環功能，達到增進人體新陳代謝效果，亦可以有效反射人體之輻射熱，使減低人體體熱逸散，使紗線具備積極式蓄熱保溫的機能性，為長效型遠紅外線機能纖維，不因水洗次數增加而減低其機能性，真正達到長效型蓄熱保暖效果，遠東先進生產的尼龍6,6遠紅外線紗dpf可達到1，手感及機能性兼具，經檢測數據證明，不論在遠紅外線放射率及遠紅外線升溫值皆比一般同規格尼龍6,6紗線優越，是結合機能性與時尚的卓越素材。</p>",
    "contentTitle2": "涼爽舒適尼龍6,6纖維",
    "content2": "<p class=\"align-middle\">遠東先進尼龍6,6「FEFC<sup>®</sup>cool隔熱涼爽舒適機能纖維」，新開發長效型隔熱涼感尼龍6,6纖維，採用奈米等級特殊添加劑，於紡絲過程中添加入尼龍6,6熔體內，與尼龍6,6達成高度完美的分散性融合，可以達到反射太陽光近紅外線波長，有效阻隔熱源功能，進而使人體肌膚達到涼感舒適及預防肌膚損害的效果，可以作為親肌膚層運動休閒布料的卓越開發素材。</p>"
  },
  "indexItem3": {
    "itemTitle": "Eco Green",
    "itemSubtitle": "FEFC<sup>®</sup>eco",
    "itemIntroduce": "尼龍6,6環保纖維，服裝產業正在開展多項綠色方案。FEFC<sup>®</sup>通過提供綠色生產方式回應產業需求聲音：FEFC<sup>®</sup>Solution Dye/Recycled/PFC-Free DWR Nylon 6,6 紗線展示了節能、減排和新的環保解決方案，同時保持其紗線性能。",
    "contentTitle1": "尼龍6,6原液染色纖維",
    "content1": "<ul class=\"list-unstyled\"><li><h5 class=\"mr-1 mt-2 mb-0\">Color Solution:</h5>於紡絲工程中將無機顏料添加入尼龍6,6原料中，使紡出的紗線具有良好的色度，色牢度比PieceDye優良，能克服Piece Dye在牢度上不佳的問題。</li><li><h5 class=\"mr-1 mt-2 mb-0\">It’s Green:</h5>原液染色纖維可以減少染整過程化學染料助劑的使用、降低廢水處理及有害物質等問題，可以有效減少能源消耗及CO<sub>2</sub>排放，具有環保節能減碳及降低環境危害效益。</li><li><h5 class=\"mr-1 mt-2 mb-0\">Soft Touch:</h5>遠東先進所生產的原液染色尼龍6,6纖維，dpf可達到1.0，物性均勻，手感及色相均佳，可直接使用於素面布料或與常規原絲作搭配，創造出布面立體外觀，亦可於紗線加工時與常規紗作合撚，再應用於平織或針織織造，創造特殊麻花外觀效果。</li></ul>",
    "contentTitle2": "尼龍6,6回收環保纖維",
    "content2": "遠東先進尼龍6,6回收環保纖維運用紡絲製程廢絲及廢塊為原料，經過回收整理、熔融、過濾及再造粒等過程，循環再製成紡絲級尼龍粒，重新熔融紡絲。如此再生循環過程可以省略聚合階段，減少77%的能源消耗並降低75%二氧化碳產生及排放。",
    "contentTitle3": "FEFC<sup>®</sup> 無氟撥水尼龍6,6纖維",
    "content3": "FEFC<sup>®</sup>無氟撥水尼龍6,6纖維為一創新突破、不含氟元素且具有長效撥水效果的尼龍6,6環保紗線，經過水洗25次測試，撥水等級仍維持4級以上，除具有無氟撥水功能之外更兼具了尼龍6,6纖維的耐磨性及柔軟特性，非常適合於戶外運動布料及鞋材布料的應用。",
    "contentTitle4": "FEFC<sup>®</sup> PFC-Free DWR Nylon 6,6 Filament",
    "content4": "FEFC<sup>®</sup> DWR，在持久防水尼龍6,6長絲永久功能方面的突破性創新技術，零PFC",
    "content5": "經過25次水洗測試，證明其等級仍高達80（含）以上",
    "content6": "為我們的環境創造具有卓越手感和新生態友好解決方案的戶外服裝或鞋類材料。",
    "table1": {
      "Fabric": "面料組織",
      "Use": "運用",
      "Fabricitem": "N66 40D DWR FDY + Lycra 30D,S/J",
      "Useitem": "泳裝",
      "Testmethod": "測試方法",
      "Testitem1": "AATCC 22",
      "Testitem2": "ISO 4920",
      "Testitem3": "JIS L 1092",
      "Washingtimes": "水洗次數",
      "Washingmethod": "水洗方式",
      "Washingitem": "AATCC 135-2012 Option 1 (2)III A(ii) 4Lb Load"
    },
    "table2": {
      "Fabric": "面料組織",
      "Use": "運用",
      "Fabricitem": "N66 40D DWR FDY, woven",
      "Useitem": "外套",
      "Testmethod": "測試方法",
      "Testitem1": "AATCC 22",
      "Testitem2": "ISO 4920",
      "Testitem3": "JIS L 1092",
      "Washingtimes": "水洗次數",
      "Washingmethod": "水洗方式",
      "Washingitem": "AATCC 135-2012 Option 1 (2)III A(ii) 4Lb Load"
    }
  },
  "indexItem4": {
    "itemTitle": "強韌",
    "itemSubtitle": "",
    "contentTitle1": "全用途強韌尼龍6,6紗",
    "content1": "<p class=\"align-middle\">FEFC強韌耐磨柔軟尼龍6,6紗線，可以應用在極端環境/氣候/運動/軍事等不同用途的服裝上，以應付不同需求下對於布料強韌需求的考驗，同時兼具良好的布料手感，可以提供對於強韌，耐磨，耐刮、等需求的布料解決方案。</p>"
  },
  "indexItem5": {
    "itemTitle": "時尚",
    "itemSubtitle": "",
    "contentTitle1": "質感光澤尼龍6,6紗",
    "content1": "<p class=\"align-middle\">FEFC質感光澤尼龍6,6紗線，重新定義布面亮度/質感/色彩，在室內/戶外運動，瑜珈/訓練，時尚成衣、等服裝設計上，提供與過去截然不同的光澤感，同時也能為設計師有更多元的設計元素，創造許多未來性於服裝產業。</p>"
  },
  "indexItem6": {
    "itemTitle": "手感",
    "itemSubtitle": "",
    "contentTitle1": "FEFC<sup>®</sup>soft~ 極緻柔細觸感尼龍6,6纖維",
    "contentSubtitle1": "異收縮舒適彈尼龍6,6紗",
    "content1": "<p class=\"align-middle\">遠東先進尼龍6,6「FEFC<sup>®</sup>soft極緻柔細觸感纖維」，為新開發輕量超高條數尼龍6,6纖維產品，挑戰直紡紗線纖細度的極致性，單絲丹尼小於0.4dpf，單根纖維直徑細化至僅8微米(μm)，<span class=\"bg-hightlight\">約頭髮直徑的1/10</span>，極致的纖維彎曲可繞性及高表面積覆蓋性，結合了尼龍6,6本身卓越的回彈性，賦予了布料超細緻柔軟的觸感，適用於對手感有極度要求的女用內衣布料及室內外運動服飾開發用途。</p>"
  },
  "About": {
    "list": {
      "item1": "關於 FEFC",
      "item2": "里程碑",
      "item3": "價值觀",
      "item4": "誠信經營守則",
      "item5": "安全衛生環保政策",
      "item6": "能源政策"
    },
    "page1": {
      "title": "關於 FEFC",
      "content1": "遠東先進纖維股份有限公司現為遠東企業集團的子公司，創立於一九九五年，結合美國杜邦公司和遠東企業雙方的優勢共同投資成立，以最先進的科技設備及紡織技術投入生產和銷售具功能性、舒適性及高附加價值的尼龍6,6纖維產品，為服飾業提供各種高級纖維精品。",
      "content2": "以創新和差異化為客戶創造優質價值的精神，提供客戶卓越品質的產品與服務向來是遠東先進纖維一向所貫徹的承諾。所以我們除了經由新產品開發團隊契而不捨的創新研發，而不斷推出高附加價值的新產品，同時也藉由跨部門的研究改善小組，運用實驗設計法分析瑕疵改善製程，以提昇品質及效率，更進而為客戶創造優質價值，超越客戶期望，增進客戶滿意度。“創新”與“品質”成功地為我們的產品打造了智慧與美的象徵。",
      "content3": "我們的目標在開創化纖紡織產業的新紀元，做為產業中的產品創新及品質領導者，進而為增進台灣產業競爭力做最大的努力。這幾年來，遠東先進纖維運用高科技的生產技術，不斷研發突破創新。以全方位的服務及“差異化”(Differentiation Strategy)的產品及行銷策略，持續提昇品質標準，為客戶及整體產業創造價值。這也是企業的成功關鍵及永續經營之道。"
    },
    "page2": {
      "title": "能源政策",
      "content1": "&emsp;&emsp;遠東先進纖維股份有限公司為遠東企業集團的子公司，創立於一九九五年，以最先進的科技設備及紡織技術生產和銷售具功能性、舒適性及高附加價值的尼龍6,6纖維產品，為業界提供各種高級纖維精品。",
      "content2": "為善盡企業社會責任及企業永續經營，秉持節能減碳之理念，推動能源循環永續利用導入綠色設計與綠色生產技術，我們的作法如下：",
      "content3": "<li class=\"mb-2\">提升能源使用效能，持續改善能源績效。</li><li class=\"mb-2\">遵守能源法規要求，兼顧利害相關需求。</li><li class=\"mb-2\">審查能源目標標的，確保資訊資源充足。</li><li class=\"mb-2\">落實能源管理系統。全員參與節能減碳。</li><li class=\"mb-2\">改善能源績效設計，優先採購節能產品。</li>"
    },
    "page3": {
      "title": "里程碑",
      "item1": "1993",
      "content1": "美商杜邦公司與遠東集團簽定合作意向書。",
      "item2": "1995~1996",
      "content2": "破土及開始建廠。",
      "item3": "1997",
      "content3": "正式試車及開工。",
      "item4": "1998",
      "content4": "榮獲勞工處頒發安全衛生自動檢查優良單位獎。",
      "item5": "1999",
      "content5": "生產線全能量產及達成100萬安全工時。",
      "item6": "2000",
      "content6": "維持全年生產線全能量產、獲頒經濟部最佳環保企業獎章。",
      "item7": "2001",
      "content7": "達成150萬安全工時。榮獲經濟部最佳品質改進成功案例獎。通過ISO 9001認證。",
      "item8": "2002",
      "content8": "榮獲經濟部最佳品質改進成功案例獎。",
      "item9": "2003",
      "content9": "達成200萬 (無損工) 安全工時。",
      "item10": "2004",
      "content10": "通過杜邦公司的製程安全管理系統稽查。遠東杜邦公司更名為遠東英威達公司。",
      "item11": "2006",
      "content11": "達成300萬 (無損工) 安全工時。榮獲桃園縣政府頒發“職場健康促進成效卓越獎”。",
      "item12": "2007",
      "content12": "榮獲INVISTA 英威達頒發安全衛生環保卓越獎。",
      "item13": "2008",
      "content13": "遠東英威達更名為遠東先進纖維公司。",
      "item14": "2009",
      "content14": "通過BSI (英國標準協會) 溫室氣體盤查驗證。達成400萬 (無損工) 安全工時。",
      "item15": "2012",
      "content15": "勞委會頒發無災害工時紀錄達成獎牌。達成500萬 (無損工) 安全工時。通過英商勞氏檢驗公司職業安全衛生管理系統(OHSAS 18001)及TOSHMS(CNS15506)驗證。",
      "item16": "2013",
      "content16": "達成550萬 (無損工) 安全工時。通過Oeko-Tex<sup>®</sup> Standard國際認證。",
      "item17": "2015",
      "content17": "遠東先進再生紗FEFC<sup>®</sup> eco通過德國萊因 (TÜV Rheinland)再生材質認證(Recycled Material Verified) Content greater than 90% of Pre-consumer Recycled Nylon Yarn (遠東先進再生紗FEFC<sup>®</sup> eco產品含有大於90%回收紗)。",
      "item18": "2016",
      "content18": "完成2015年度溫室氣體(GHG)外部盤查作業，對比2006基準年，每噸產品碳排放降幅超過30%，在節能減碳方面的努力展現卓越成效。",
      "item19": "2017",
      "content19": "遠東先進回收紗FEFC<sup>®</sup> eco通過荷蘭管制聯盟Control Union Certifications ) 全球回收標準驗證Global Recycled Standard (GRS) version 3.0, Contain 92% of Pre-consumer Recycled Nylon Yarn (遠東先進再生紗FEFC<sup>®</sup>eco 產品含有92%回收紗)。",
      "item20": "2018",
      "content20": "達成800萬 (無損工) 安全工時。遠東先進回收紗FEFC<sup>®</sup>eco通過荷蘭管制聯盟Control Union Certifications ) 全球回收標準驗證Global Recycled Standard (GRS) version 3.0, Contain 92%、100% of Pre-consumer Recycled Nylon Yarn (遠東先進再生紗FEFC<sup>®</sup> eco 產品含有92%、100%回收紗)。",
      "item21": "2020",
      "content21": "達成900萬（無損工）安全工時。",
      "item22": "2020",
      "content22": "ISO45001 (職業安全衛生管理系統) 通過英商勞氏公司驗證。",
      "item23": "2021",
      "content23": " 遠東先進回收紗FEFC<sup>®</sup> eco 通過IDFL (International Down and Feather Testing Laboratory)全球回收標準驗證 Global Recycle Standard(GRS) version 4.0, Contain 100% of Pre-consumer Recycle Nylon Yarn (遠東先進再生紗FEFC<sup>®</sup> eco 產品含有100%回收紗)。",
      "item24": "2021",
      "content24": "遠東先進回收紗FEFC<sup>®</sup> eco 通過瑞士紡織檢定中心(TESTEX)OEKO-TEX Standard 100 Class 1 標準。"
    },
    "page4": {
      "title": "安全衛生環保政策",
      "content1": "&emsp;&emsp;遠東先進纖維股份有限公司為遠東企業集團的子公司，創立於一九九五年，以最先進的科技設備及紡織技術生產和銷售具功能性、舒適性及高附加價值的尼龍6,6纖維產品，為業界提供各種高級纖維精品。",
      "content2": " &emsp;&emsp;遠東先進纖維承諾以維護員工、客戶、承攬商與社會大眾的安全、衛生與環境保護的方式，管理公司的營運與生產、配送和行銷產品。經由全員諮詢與參與安全衛生環保的事務與活動，並持續改善人員、設施與製程等管理措施，進而提升安全績效，減少及預防工作傷害與疾病，積極促進員工健康，及遵守法令與規章，朝向『零事故』的目標邁進。",
      "item3": "本公司對於安全衛生環境管理系統的承諾如下：",
      "content3": "<li class=\"mb-2\"><span class=\"font-weight-bold\">諮詢參與：</span><div>安全、衛生與環保是每一位員工的責任。鼓勵員工積極參與公司安全、衛生與環保事務與活動，以落實安全、衛生與環保人人有責的觀念。</div></li><li class=\"mb-2\"><span class=\"font-weight-bold\">持續改善：</span><div>落實持續改善精神。透過經常性的安全觀察與矯正措施的執行，為員工創造一個安全、衛生與環保的友善工作環境。</div></li><li class=\"mb-2\"><span class=\"font-weight-bold\">災害預防：</span><div>所有的意外事件皆可避免。無論是在設備的操作、人員的作業、危害物質的處理、製程危害與變更管理上，預防職業傷害、疾病與環境污染事件的發生均是我們的首要工作。</div></li><li class=\"mb-2\"><span class=\"font-weight-bold\">健康促進：</span><div>將健康的觀念推展至公司所有員工，積極教育員工管理自己的身心健康，培養正向的健康態度及良好習慣。</div></li><li class=\"mb-2\"><span class=\"font-weight-bold\">遵守法規：</span><div>承諾遵守並符合政府所公布之各項安全、衛生與環境保護相關法規與其他要求事項。</div></li>"
    },
    "page5": {
      "title": "誠信經營守則",
      "rule": {
        "item1": "1.目的:",
        "itemContent1": "為本公司之永續發展，並建立誠信經營之企業文化，特訂定本守則。",
        "item2": "2.範圍:",
        "itemContent2": "本守則適用於本公司董事、經理人、受僱人、受任人或具有實質控制能力者（以下簡稱實質控制者）。前述適用對象以下簡稱為「本公司人員」。",
        "item3": "3.內容:",
        "itemContent3": {
          "list1": "3.1 禁止不誠信行為",
          "content1": "<ul><li>本公司人員於從事商業行為之過程中，不得直接或間接提供、承諾、要求或收受任何不正當利益，或做出其他違反誠信、不法或違背受託義務等不誠信行為，以求獲得或維持利益（以下簡稱不誠信行為）。</li><li>前項行為之對象，包括公職人員、參政候選人、政黨或黨職人員，以及任何公、民營企業或機構及其董事(理事)、監察人(監事)、經理人、受僱人、實質控制者或其他利害關係人。</li></ul>",
          "list2": "3.2 利益之態樣",
          "content2": "本守則所稱利益，係指任何有價值之事物，包括任何形式或名義之金錢、餽贈、佣金、職位、服務、優待、回扣等。但屬正常社交禮俗，且係偶發而無影響特定權利義務之虞時，不在此限。",
          "list3": "3.3 法令遵循",
          "content3": "本公司應遵守公司法、證券交易法、商業會計法、政治獻金法、貪污治罪條例、政府採購法、公職人員利益衝突迴避法、上市相關規章或其他商業行為有關法令，以作為落實誠信經營之基本前提。",
          "list4": "3.4 政策",
          "content4": "本公司本於廉潔、透明及負責之經營理念，制定以誠信為基礎之政策，並建立良好之公司治理與風險控管機制，以創造永續發展之經營環境。",
          "list5": "3.5 防範方案",
          "content5": "本公司依本守則並參酌國內外通用之標準或指引訂定防範不誠信行為方案，包含作業程序、行為指南及教育訓練等，並具體載明本公司人員執行業務應注意事項。",
          "list6": "3.6 承諾與執行",
          "content6": "<div class=\"pl-3\"></div><ul><li>本公司應要求董事與高階管理階層出具遵循誠信經營政策之聲明，並於僱用條件要求受僱人遵守誠信經營政策。</li><li>本公司於公司網站中明示誠信經營之政策，董事會與高階管理階層應承諾積極落實，並於內部管理及商業活動中確實執行。</li></ul>",
          "list7": "3.7 禁止侵害智慧財產權",
          "content7": "本公司人員應遵守智慧財產相關法規、公司內部作業程序及契約規定；未經智慧財產權所有人同意，不得使用、洩漏、處分、燬損或有其他侵害營業秘密、商標、專利、著作等智慧財產權之行為。",
          "list8": "3.8 誠信經營商業活動及禁止從事不公平競爭之行為",
          "content8": "<ul><li>本公司以公平之方式進行商業活動，並依相關競爭法規從事營業活動，不得固定價格、操縱投標、限制產量與配額，或以分配顧客、供應商、營運區域或商業種類等方式，分享或分割市場。</li><li>本公司於商業往來之前，將考量代理商、供應商、客戶或其他商業往來交易對象之合法性及是否有不誠信行為紀錄，並避免與有不誠信行為紀錄者進行交易。</li><li>本公司與他人簽訂重要契約時，內容應包含遵守誠信經營政策及交易相對人如涉及不誠信行為，得隨時終止或解除契約之條款。</li></ul>",
          "list9": "3.9 禁止行賄及收賄",
          "content9": "本公司人員應操守嚴謹，於執行業務時，不得直接或間接提供、承諾、要求或收受任何形式之不正當利益，包括賄賂、回扣、佣金、疏通費或透過其他途徑向客戶、代理商、承包商、供應商、公職人員或其他利害關係人提供或收受不正當利益。但符合營運所在地法律者，不在此限。",
          "list10": "3.10 禁止提供非法政治獻金",
          "content10": "本公司人員對政黨或參與政治活動之組織或個人直接或間接提供捐獻，應符合政治獻金法及公司內部相關作業程序，不得藉以謀取商業利益或交易優勢。",
          "list11": "3.11 禁止不當慈善捐贈或贊助",
          "content11": "本公司人員對於慈善捐贈或贊助，應符合相關法令及內部作業程序，不得為變相行賄。",
          "list12": "3.12 禁止不合理禮物、款待或其他不正當利益",
          "content12": "本公司及其董事、經理人與員工，不得直接或間接提供或接受任何不合理禮物、款待或其他不正當利益，建立商業關係或影響商業交易行為。",
          "list13": "3.13 保密協定",
          "content13": "<ul><li>本公司人員應遵守證券交易法之規定，不得利用所知悉之未公開資訊從事內線交易，亦不得洩露予他人，以防止他人利用該未公開資訊從事內線交易。</li><li>參與本公司合併、分割、收購及股份受讓、重要備忘錄、策略聯盟、其他業務合作計畫或重要契約之其他機構或人員，應與本公司簽署保密協定，承諾不洩露其所知悉之本公司商業機密或其他重大資訊予他人，且非經本公司同意不得使用該資訊。</li></ul>",
          "list14": "3.14 組織與責任",
          "content14": "<ul><li>本公司人員應盡善良管理人之注意義務，督促公司防止不誠信行為，並隨時檢討其實施成效及持續改進，確保誠信經營政策之落實。</li><li>本公司為健全誠信經營之管理，由隸屬於董事會之公司治理主管及人力資源處負責誠信經營政策與防範方案之制定及監督執行，並定期(至少一年一次)向董事會報告。</li></ul>",
          "list15": "3.15 業務執行之法令遵循",
          "content15": "<ul><li>本公司人員於執行業務時，應遵守法令規定及防範方案。</li><li>本公司人員均應遵守本公司一切章程、規則、辦法、細則及其他上級主管指示事項之規定。</li></ul>",
          "list16": "3.16 本公司人員之利益迴避",
          "content16": "<ul><li>本公司制定防止利益衝突之政策，並提供適當管道供本公司人員主動說明其與公司有無潛在之利益衝突。</li><li>本公司董事應秉持高度自律，對董事會所列議案，與其自身或其代表之法人有利害關係，致有害於公司利益之虞者，得陳述意見及答詢，不得加入討論及表決，且討論及表決時應予迴避，並不得代理其他董事行使其表決權。董事間亦應自律，不得不當相互支援。</li><li>本公司人員不得藉其在公司擔任之職位，使其自身、配偶、父母、子女或任何他人獲得不正當利益。</li></ul>",
          "list17": "3.17 會計與內部控制",
          "content17": "<ul><li>本公司就具較高不誠信行為風險之營業活動，建立有效之會計制度及內部控制制度，無外帳或保留秘密帳戶，並隨時檢討，俾確保制度之設計及執行持續有效。</li><li>本公司內部稽核人員應定期查核前項制度遵循情形，並作成稽核報告提報董事會。</li></ul>",
          "list18": "3.18 教育訓練及考核",
          "content18": "<ul><li>本公司定期對本公司人員舉辦教育訓練與宣導，各業務承辦單位宜對與公司從事商業行為之相對人進行宣導，使其充分瞭解公司誠信經營之決心、政策、防範方案及違反不誠信行為之後果。</li><li>本公司各級主管對所屬人員之學識、操守、工作能力、誠信經營政策及績效應隨時加以考評，以為年度考績之依據。</li></ul>",
          "list19": "3.19 檢舉與懲戒與申訴制度",
          "content19": "<ul><li>本公司人員發現有違反誠信經營規定之情事，應主動向審計委員會、部門主管、內部稽核主管、公司治理主管、人力資源處或其他適當主管檢舉。公司對於檢舉人身分及檢舉內容將確實保密。</li><li>本公司營運堅持零弊端原則，本公司人員若利用本身職務之便，意圖謀取自己或他人之不當利益，致本公司蒙受損失，除應予以解除職務外，並應無條件賠償本公司因此所受之一切損失。</li><li>本公司人員如有違反誠信經營規定者，本公司將視情節輕重，依本公司獎懲辦法予以懲處；凡經免職人員永遠不得在本公司及關係企業任職。必要時得向主管機關報告或移送司法機關偵辦。</li><li>本公司訂有「誠信經營作業程序及行為指南」具體規範本公司人員於執行業務時應注意之事項。</li></ul>",
          "list20": "3.20 本守則之檢討修正",
          "content20": "本公司應隨時注意國內外誠信經營相關規範之發展，並鼓勵本公司人員提出建議，據以檢討改進本守則，以提昇公司誠信經營之成效。",
          "list21": "3.21 實施",
          "content21": "本守則經總經理核准後實施，修正時亦同。"
        }
      }
    },
    "page6": {
      "title": "價值觀",
      "content": "FEFC領導團隊，完全承諾並致力於落實FEFC價值觀，經由自身的典範領導帶領團隊以建造世界級的組織運作。",
      "list": {
        "item1": "誠、勤、樸、慎",
        "content1": "以遠東集團的創業精神『誠、勤、樸、慎』，作為FEFC事業管理的指導原則。",
        "item2": "環保、健康、安全與遵循法規",
        "content2": "處理所有事物皆應符合法規與道德規範。在所有事業營運領域上，戮力追求環保、安全以及相關遵循法規的卓越績效。",
        "item3": "創新和品質",
        "content3": "創造和實現優越的價值，以符合或超越客戶的需求，並強化公司長期的競爭力。",
        "item4": "使命必達",
        "content4": "建立一個負當責、守紀律、實踐承諾，而專注於事業和以成果為導向的組織文化，以實現公司的願景。",
        "item5": "熱忱和堅持卓越",
        "content5": "展現強烈的企業家精神和積極主動的態度，致力於持續改善，使我們所做的任何一件事，都能達到高標準。",
        "item6": "速度和彈性",
        "content6": "引導改變，利用改變，和強化危機意識，以因應快速改變的事業環境。",
        "item7": "以人為本和團隊精神",
        "content7": "以尊嚴、尊重、和體恤的態度對待他人。肯定多元的價值，激發個人的最大潛能。建立分工合作的工作關係，以打破組織內的藩籬和去除官僚體制。",
        "item8": "學習與教導",
        "content8": "塑造終身學習與持續成長的文化，以強化組織能力。"
      }
    }
  },
  "ContactUs": {
    "title": "聯絡我們",
    "item1": "業務",
    "item2": "招募"
  },
  "Hr": {
    "title": "人才招募",
    "content1": "誠摯邀請",
    "content2": "充滿熱情及企圖心的您",
    "content3": "加入我們",
    "content4": "最新職缺",
    "item1": "人才培育",
    "itemContent1": "提供多元學習管道與訓練資源，培養職場所需的專業知識、技能以及樂觀進取的工作態度進而提升工作績效與營運成果。",
    "item2": "人才培育系統與架構",
    "itemContent2": "完整有系統地職能發展與學習計畫，讓員工發展各階段所需的能力，結合集團人才發展中心與產學合作計畫資源，實現員工自我發展目標與培育未來專業與領導人才。",
    "list": "<li class=\"mb-1\"><strong>新進員工引導訓練：</strong>輕鬆活潑跨單位的講師，協助新進員工了解企業文化，融入公司溫馨大家庭。</li><li class=\"mb-1\"><strong>職能訓練：</strong>每年的職能訓練發展計畫，培育員工執行各項業務所需的專業技能與執照。</li><li class=\"mb-1\"><strong>儲備幹部培育計畫：</strong>透過跨單位專業講師的引導，孕育未來領袖人才。</li><li class=\"mb-1\"><strong>基層主管培育計畫：</strong>跨單位基層主管共同學習，建立團隊默契與情感，在各領域專業講師的帶領，發展經營管理與領導能力。</li><li class=\"mb-1\"><strong>產學合作計畫：</strong>參與國家產學合作計畫，提供在學學生實習環境，創造產學雙贏的合作模式，善盡企業社會責任，培育國家社會未來所需之人才。</li><li class=\"mb-1\"><strong>集團菁英人才培育計畫：</strong>發揮集團綜效，培訓優秀的專業與領導人才，提升公司與產業競爭力。</li>"
  },
  "Tactel": {
    "title": "TACTEL/SUPPLEX",
    "item1": "TACTEL<sup>®</sup> fabric",
    "content1": "Soft. Lift. Surprisingly strong. TACTEL<sup>®</sup> fabric creates garments that are soft, supplely smooth, breathable and lightweight. Because of its soft and light-weight nature, TACTEL<sup>®</sup> fabric is widely used in women’s intimate apparel. TACTEL<sup>®</sup> fabric are also used in a vast collection of men’s, women’s and children’s clothing and even accessories.",
    "item2": "SUPPLEX<sup>®</sup> fabric",
    "content2": "SUPPLEX<sup>®</sup> fabric offers consumers a feel similar to cotton with the benefits of advanced fiber technology. Cotton’s natural texture and aesthetics make it the fabric of choice in many garments categories. Garments make with cotton can sometimes crease and shrink easily and they often fade in color. SUPPLEX<sup>®</sup> fabric – a family that combines the traditional apparel of cotton with the performance benefit of modern fiber technology. SUPPLEX<sup>®</sup> fabrics are breathable, hold their shape, dry faster than cotton and retain their color.",
    "content3": "TACTEL<sup>®</sup> and SUPPLEX<sup>®</sup> are trademarks of The LYCRA Company and are used under license to FEFC for its Nylon 6,6 products."
  },
  "items": [
    {
      "title": "綠能環保",
      "imageUrl": "greeneco.png",
      "url": "/greeneco",
      "detailTextArray": ["原液染色纖維", "回收環保纖維", "無氟撥水纖維"]
    },
    {
      "title": "舒彈",
      "imageUrl": "comfort.png",
      "url": "/comfort",
      "detailTextArray": ["舒適", "延伸"]
    },
    {
      "title": "手感",
      "imageUrl": "superhand.png",
      "url": "/superhand",
      "detailTextArray": ["超觸感", "超體會"]
    },
    {
      "title": "時尚",
      "imageUrl": "style.png",
      "url": "/style",
      "detailTextArray": ["風格", "外觀"]
    },
    {
      "title": "機能",
      "imageUrl": "functions.png",
      "url": "/functions",
      "detailTextArray": ["功能", "用途"]
    },
    {
      "title": "強韌",
      "imageUrl": "strength.png",
      "url": "/strength",
      "detailTextArray": ["强度", "耐用"]
    }
  ],
  "More": "更多...",
  "Aboutlist": "關於",
  "CompanyProfile": "公司介紹",
  "personnel_recruitment": "人才招募",
  "Contact_us": "聯絡我們",
  "Product_Information": "產品資訊"
}
