import {createStore} from "vuex";

const types = {
  SET_PAGE_INDEX: "SET_PAGE_INDEX"
};

export default createStore({
  state: {
    pageIndex: 0
  },
  getters: {
    pageIndex: state => state.pageIndex
  },
  mutations: {
    [types.SET_PAGE_INDEX](state, data) {
      state.pageIndex = data;
    }
  },
  actions: {
    setPageIndex({
      commit
    }, data) {
      commit(types.SET_PAGE_INDEX, data);
    }
  },
  modules: {}
});
