<template>
  <div class="container">
    <div
      id="carouselExampleCaptions"
      class="carousel slide w-100"
      data-ride="carousel"
    >
      <ol class="carousel-indicators">
        <li
          v-for="(elem, index) in [0, 0, 0, 0, 0, 0]"
          :key="index"
          data-target="#carouselExampleCaptions"
          :data-slide-to="index"
          :class="{ active: index === 0 }"
        ></li>
      </ol>
      <div class="carousel-inner">
        <div
          v-for="(elem, index) in ['home', '1', '2', '3', '4', '5']"
          :key="index"
          class="carousel-item"
          :class="{ active: index === 0 }"
        >
          <img
            :src="require(`@/assets/header/${elem}.jpg`)"
            class="d-block w-100"
            alt="..."
          />
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleCaptions"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleCaptions"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
  <div class="container my-4">
    <div class="px-md-2 mx-md-2 px-lg-3 mx-lg-3 px-xl-5 mx-xl-5">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          class="embed-responsive-item"
          src="https://www.youtube.com/embed/gTbuIH3fHDc?rel=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>

  <div class="my-2 py-2 text-center">
    <h4 class="">{{ $t("introduct") }}</h4>
  </div>
  <div class="container px-0 px-md-2 mt-2 mb-5">
    <div class="d-flex flex-column">
      <HomeItem
        v-for="(item, index) in items"
        :key="index"
        :title="item.title"
        :imageUrl="item.imageUrl"
        :url="item.url"
        :detailTextArray="item.detailTextArray"
        :index="index"
      />
    </div>
  </div>
</template>

<script>
import HomeItem from "@/components/home2/HomeItem";

export default {
  data() {
    return {
      items: [
        {
          title: "綠能環保",
          imageUrl: "greeneco.png",
          url: "/greeneco",
          detailTextArray: ["原液染色纖維", "回收環保纖維", "無氟撥水纖維"],
        },
        {
          title: "舒彈",
          imageUrl: "comfort.png",
          url: "/comfort",
          detailTextArray: ["舒適", "延伸"],
        },
        {
          title: "手感",
          imageUrl: "superhand.png",
          url: "/superhand",
          detailTextArray: ["超觸感", "超體會"],
        },
        {
          title: "時尚",
          imageUrl: "style.png",
          url: "/style",
          detailTextArray: ["風格", "外觀"],
        },
        {
          title: "機能",
          imageUrl: "functions.png",
          url: "/functions",
          detailTextArray: ["功能", "用途"],
        },
        {
          title: "強韌",
          imageUrl: "strength.png",
          url: "/strength",
          detailTextArray: ["强度", "耐用"],
        },
      ],
    };
  },
  setup() {
    return {};
  },
  components: {
    HomeItem,
  },
};
</script>

<style lang="scss" scoped></style>