import {createRouter, createWebHashHistory} from "vue-router";
import Home from "../views/Home2.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  }, {
    path: "/greeneco",
    name: "greeneco",
    //  route level code-splitting
    //  this generates a separate chunk (about.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import (
    /* webpackChunkName: "about" */
    "@/views/item/GreenEvo.vue")
  }, {
    path: "/comfort",
    name: "comfort",
    //  route level code-splitting
    //  this generates a separate chunk (about.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import (
    /* webpackChunkName: "about" */
    "@/views/item/Comfort.vue")
  }, {
    path: "/superhand",
    name: "superhand",
    //  route level code-splitting
    //  this generates a separate chunk (about.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import (
    /* webpackChunkName: "about" */
    "@/views/item/Superhand.vue")
  }, {
    path: "/style",
    name: "style",
    //  route level code-splitting
    //  this generates a separate chunk (about.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import (
    /* webpackChunkName: "about" */
    "@/views/item/Style.vue")
  }, {
    path: "/functions",
    name: "functions",
    //  route level code-splitting
    //  this generates a separate chunk (about.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import (
    /* webpackChunkName: "about" */
    "@/views/item/Functions.vue")
  }, {
    path: "/strength",
    name: "strength",
    //  route level code-splitting
    //  this generates a separate chunk (about.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import (
    /* webpackChunkName: "about" */
    "@/views/item/Strength.vue")
  }, {
    path: "/about",
    name: "About",
    //  route level code-splitting
    //  this generates a separate chunk (about.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import (
    /* webpackChunkName: "about" */
    "@/views/About.vue")
  }, {
    path: "/contactus",
    name: "ContactUs",
    //  route level code-splitting
    //  this generates a separate chunk (about.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import (
    /* webpackChunkName: "about" */
    "@/views/ContactUs.vue")
  }, {
    path: "/hr",
    name: "Hr",
    //  route level code-splitting
    //  this generates a separate chunk (about.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import (
    /* webpackChunkName: "about" */
    "@/views/Hr.vue")
  }, {
    path: "/tactel",
    name: "Tactel",
    //  route level code-splitting
    //  this generates a separate chunk (about.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import (
    /* webpackChunkName: "about" */
    "@/views/Tactel.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(), //createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return {top: 0};
  }
});

export default router;
