{
  "message": "hello i18n !!",
  "changeLocale": "Language",
  "introduct": "FEFC® Product Offerings",
  "product1": {
    "title": "Nylon 6,6 is Your Best Choice.",
    "list1": "Over 15% lighter in weight than polyester.",
    "list2": "20% stronger, by weight, than polyester and thus fabrics are more resistant to pilling and tearing.",
    "list3": "Stronger and better abrasion resistance than nylon 6 and polyester.",
    "list4": "Superior soft hand than nylon 6 and polyester.",
    "list5": "Better wash fastness than nylon 6 and polyester.",
    "list6": "Low shrinkage and good recovery of fabric lead to good dimensional stability due to intrinsic nylon 6,6 properties.",
    "list7": "Robust in after-treatment processes e.g. lamination, coating for multi- function fabric development."
  },
  "product2": {
    "title": "Nylon 6,6",
    "list1": {
      "title": "Lightweight",
      "content": "Ultra-lite nylon 6,6 filaments with soft handle and strength are used to produce fabrics for lightweight downproof, packable jackets, fine-gauge knitting intimate apparel providing comfort of weightless. Sheer and see-through effects meet the requirement of second skin story."
    },
    "list2": {
      "title": "Super Hand Feel",
      "content": "Supermicro nylon 6,6 filaments with cutting-edge fineness are used to produce fabrics for downproof jacket, sleeping bag, outdoor wears and intimates delivering superior softness and excellent coverage effect of fabric."
    },
    "list3": {
      "title": "Strength & Durability",
      "content": "High tenacity nylon 6,6 filaments with exceptional robustness are used to produce fabrics for outdoor wears, jackets, performing excellent tearing, tensile strength, abrasion resistance with enhanced hand feel."
    },
    "list4": {
      "title": "Comfort Stretch",
      "content": "Innovative nylon 6,6 filaments with soft stretch property are used to produce woven stretch fabrics without the use of spandex providing lightweight, softness, color fastness and dimensional stability. Warp knit fabric made of superior textured yarn provides bulkiness, comfort, and stability of fabric."
    },
    "list5": {
      "title": "Style & Looks",
      "content": "A diverse range of fine filaments with full range of lustres, intrinsic strength, are used to produce fabrics for all end-users with superior soft handle, jewel-like lustre and luxurious aesthetics drape."
    },
    "list6": {
      "title": "Functions",
      "content": "Advanced nylon 6,6 filaments with inherent permanent functionalities including moisture management, thermal resistance, opacity & UV-protection are used to produce fabrics for intimates, active sports, outdoor wears and legwear."
    }
  },
  "product4": {
    "title": "Solution Dye Nylon 6,6 filament ",
    "content1": "By adding color master batch to spinning process, solution dye make filament with excellent color shade and fastness and environmental-friendly as not necessary to dye it after weaving or knitting.",
    "content2": "Color Solution : 使用超亮光尼龍6,6原料，於紡絲工程中添加黑色無機顏料，使紡出的紗線一開始就具有良好 的黑色色度，各種染色牢度都比 piece dye 優良，能克服 Piece dye 在日光牢度上不佳的問題。",
    "content3": "Softness Touch : 遠東先進所生產的原抽黑尼龍6,6纖維，dpf 可達到 1，且纖維纖度及物性均勻，手感及色相均 佳，可以直接作原絲使用與常規原絲作配列創造出布面立體外觀，亦可於ATY或DTY加工時與常規紗作交絡，再 應用於平織或針織織造，可以創造特殊麻花外觀效果。",
    "content4": "It’s Green: 原液染色纖維可以減少後段染整過程中的化學染料助劑使用、廢水處理、有毒害物之物質產生等等 問題，可以有效減少能源消耗及CO2排放，具有明顯環保節能減碳及降低環境危害效益。"
  },
  "indexItem1": {
    "itemTitle": "Comfort Stretch",
    "itemSubtitle": "",
    "contentTitle1": "FEFC<sup>®</sup>Stretch & Comfortable Nylon 6,6 Filament",
    "content1": "<p class=\"align-middle\">Innovative Nylon 6,6 filaments with soft stretch property are used to produce stretch fabrics without the use of spandex providing lightweight, softness, color fastness and dimensional stability. Circular knit fabric made of superior textured yarn provides bulkiness, comfort, and stability of fabric.</p>",
    "contentTitle2": "",
    "content2": "<p class=\"align-middle\">Innovative Nylon 6,6 filament with soft stretch property are used to produce woven stretch fabrics without the use of spandex providing lightweight, softness, color fastness and dimensional stability. Warp knit fabric made of superior textured yarn provides bulkiness, comfort, and stability of fabric.</p>"
  },
  "indexItem2": {
    "itemTitle": "Functions",
    "itemSubtitle": "",
    "contentTitle1": "Nylon6,6 Far Infrared Filament",
    "content1": "<p class=\"align-middle\">By adding special treated inorganic powder into Nylon 6,6 filament during spinning process, that makes filament with function to absorb solar energy then releasing FIR . When the fabrics made of FEFC made FIR filament, it can stimulates and accelerates human body’s blood circulation which is helpful to generate body’s heat and keep body warm. The effect of releasing FIR is long lasting even after many times of washing. Our FIR filament can reach dpf 1 which demonstrate not only functionality but also superior hand feel as well as fashion.</p>",
    "contentTitle2": "FEFC<sup>®</sup>cool~ Cooling Nylon 6,6 Filament",
    "content2": "<p class=\"align-middle\">FEFC<sup>®</sup>cool is an Anti-NIR Nylon 6,6 filament brings cool and comfortable effect to body. The function was created by special nano additives into filament during spinning stage. All of the nano additives were well dispersed around the filament that gives Nylon 6,6 filament with long-lasing cool function by reflecting & cut off near infrared radiation. It creates outstanding cool and soft feel as well as comfortable to fabric. FEFC® cool is the excellent material for making base layer apparel.</p>"
  },
  "indexItem3": {
    "itemTitle": "Eco Green",
    "itemSubtitle": "FEFC<sup>®</sup>eco",
    "itemIntroduce": "Multiple green initiatives are underway in apparel industry. FEFC®echo the voice by providing its green approaches : FEFC®Solution Dye/ Recycled / PFC-Free DWR Nylon 6,6 filament demonstrate energy saving, emission cutting and new ecofriendly solutions to our environment while sustain its yarn performance.",
    "contentTitle1": "Nylon 6,6 Solution Dye Filament",
    "content1": "<ul class=\"list-unstyled\"><li><h5 class=\"mr-1 mt-2 mb-0\">Color Solution:</h5>By applying color master batch to spinning process, Solution Dye makes filament with excellent color shade and fastness which is much better than piece dye product.</li><li><h5 class=\"mr-1 mt-2 mb-0\">It’s Green:</h5>FEFC® Solution Dye Nylon 6,6 Filament is an environmental-friendly product because not necessary to dye it after weaving or knitting. It reduces the consumption of dyestuffs, dyeing auxiliaries, relieves the requirement of waste water treatment and better controls the discharge of hazard chemicals.</li><li><h5 class=\"mr-1 mt-2 mb-0\">Soft Touch:</h5>FEFC® Solution Dye Nylon 6,6 Filament reaches dpf 1.0 with good color fastness and superior hand feel. The filament can be directly used for solid color and pattern fabrics as well as textured with other regular filament for special heather effect.</li></ul>",
    "contentTitle2": "Nylon 6,6 Recycled Filament",
    "content2": "The spinning waste of FEFC Recycled Nylon 6,6 filament is recycled and reused through collecting, melting, filtrating, pelletizing and spinning processes. It can save 77% of energy consumption and reduce 75% of CO2 emission due to bypassing polymerization stage.",
    "contentTitle3": "FEFC<sup>®</sup> PFC-Free DWR Nylon 6,6 Filament",
    "content3": "A breakthrough innovative technology in durable water repellence for Nylon 6,6 filament with zero PolyFluoroCarbons (PFCs). Even after 25 wash cycles, water repellency remains at >4 grade for both knitting and woven; permitting for use in both apparel and footwear materials with superior performance in a new eco-friendly solution.",
    "contentTitle4": "FEFC<sup>®</sup> PFC-Free DWR Nylon 6,6 Filament",
    "content4": "FEFC<sup>®</sup> DWR, a breakthrough innovative technology in the permanent function of durable water repellent Nylon 6,6 filament with zero PFC",
    "content5": "After washing test 25 times, it proof of its level is still up to 80(inclusive) or more",
    "content6": "It creates outdoor garments and shoes materials with superior hand feel and new eco-friendly solution to our environment.",
    "table1": {
      "Fabric": "Fabric construction",
      "Use": "Fabric construction",
      "Fabricitem": "N66 40D DWR FDY + Lycra 30D,S/J",
      "Useitem": "SWW",
      "Testmethod": "Test Method",
      "Testitem1": "AATCC 22",
      "Testitem2": "ISO 4920",
      "Testitem3": "JIS L 1092",
      "Washingtimes": "Wash Cycle",
      "Washingmethod": "Wash Method",
      "Washingitem": "AATCC 135-2012 Option 1 (2)III A(ii) 4Lb Load"
    },
    "table2": {
      "Fabric": "Fabric construction",
      "Use": "Fabric construction",
      "Fabricitem": "N66 40D DWR FDY, woven",
      "Useitem": "Jacket",
      "Testmethod": "Test Method",
      "Testitem1": "AATCC 22",
      "Testitem2": "ISO 4920",
      "Testitem3": "JIS L 1092",
      "Washingtimes": "Wash Cycle",
      "Washingmethod": "Wash Method",
      "Washingitem": "AATCC 135-2012 Option 1 (2)III A(ii) 4Lb Load"
    }
  },
  "indexItem4": {
    "itemTitle": "Strength Durability",
    "itemSubtitle": "",
    "contentTitle1": "Strength & Durability Nylon 6,6 Filament",
    "content1": "<p class=\"align-middle\">FEFC<sup>®</sup>dura-versatile Nylon 6,6 filament can be used at various kinds of garments in extreme environment and climate, like sports/military/industrial…etc. Fabric with using FEFC® dura versatile Nylon 6,6 filament has great hand feel, easily to use at versatile critical strength needs garment. It provides a fabric solution of tears, scuffs and abrasions.</p>"
  },
  "indexItem5": {
    "itemTitle": "Style Looks",
    "itemSubtitle": "",
    "contentTitle1": "FEFC<sup>®</sup>styletex Nylon 6,6 Filament",
    "content1": "<p class=\"align-middle\">FEFC<sup>®</sup>styletex Nylon 6,6 filament – Redefined Luster/texture/Color effect on various kinds of fabric designed, like Indoor/outdoor wear, Yoga/Training wear, fashion wear…etc. It provides total different luster effect and more design elements that designer can use for garment, there are more possibility with FEFC® styletex at garment industrial.</p>"
  },
  "indexItem6": {
    "itemTitle": "Super Hand Feel",
    "itemSubtitle": "",
    "contentTitle1": "FEFC<sup>®</sup>soft~ Superior Hand Feel Nylon 6,6 Filament",
    "contentSubtitle1": "",
    "content1": "<p class=\"align-middle\">FEFC<sup>®</sup>soft is a kind of excellent super micro Nylon 6,6 filament. It was designed as high count with fine denier. The dpf of the filament down below to 0.4 and it’s diameter of single filament just about 8μm,<span class=\"bg-hightlight\">about 1/10 the diameter of the hair,</span> that gives fiber with extremely excellent flexibility、coverage effect as well as superior hand touch. FEFC® soft is the best material and choice for making any kind of fabric that soft touch was highly requested.</p>"
  },
  "About": {
    "list": {
      "item1": "About FEFC",
      "item2": "Milestones",
      "item3": "FEFC Values",
      "item4": "Sincerity rules",
      "item5": "SHE",
      "item6": "Energy"
    },
    "page1": {
      "title": "About FEFC",
      "content1": "Far Eastern Fibertech Company Limited (FEFC) now is a subsidiary of the Far Eastern Group. It was founded in 1995 as a 50-50 percent joint venture, combined the 'best of the best' from both partners of DuPont Company and Far Eastern Group. FEFC offers high-quality specialty Nylon 6,6 fibers for the apparel industry through a world-class manufacturing facility and the most advanced technology.",
      "content2": "FEFC is strongly committed to providing customers with high quality products and services through innovation and differentiation strategies. We therefore continue to develop high value-added products and improve products and services quality by a new product development team so as to create superior values to meet and exceed customer expectations.",
      "content3": "We are pursuing to create a new era of Nylon 6,6 fiber industry and to establish FEFC as the most competitive player in the world Nylon 6,6 fiber market in the areas of innovation and quality. We ceaselessly advance the technology of manufacture and break through bottleneck of innovation so that we can provide total solution services, differentiated products with superior quality to create more values for satisfactions of our customers."
    },
    "page2": {
      "title": "Far Eastern Fibertech Energy Policy",
      "content1": "&emsp;&emsp;Far Eastern Fibertech Company Limited (FEFC) is a subsidiary of the Far East Group. It was established in 1995. FEFC offers high-quality specialty and value-added Nylon 6,6 fibers for textile industrial through a world-class manufacturing facility and most advance technology.",
      "content2": "In order to fulfill Corporate Social Responsibility and sustainable business operations, Upload to the concept of energy saving and carbon reduction, and promote the sustainable use of energy recycling and introduction of green design and green production technology, our approach is as follows:",
      "content3": "<li class=\"mb-2\">Enhance energy utilize efficiency and continuously improve energy performance.</li><li class=\"mb-2\">Comply with the requirements of energy laws and regulations, match needs of interested party.</li><li class=\"mb-2\">Review objectives and energy targets to ensure the availability of information and necessary resources.</li><li class=\"mb-2\">Fulfill energy management system and all employees participate in energy saving and carbon reduction.</li><li class=\"mb-2\">Improve energy performance in design and take priority to purchasing energy-saving products.</li>"
    },
    "page3": {
      "title": "Milestones",
      "item1": "1993",
      "content1": "DuPont and Far Eastern Group signed the letter of intent and established a 50-50 joint venture DuPont Far Eastern Co., Ltd.",
      "item2": "1995~1996",
      "content2": "Undertook construction work and built up the manufacturing plant.",
      "item3": "1997",
      "content3": "Started up and suffered Asian Crisis.",
      "item4": "1998",
      "content4": "Was recognized as the superior company of safety & hygiene self-inspection by Bureau of Labor affairs.",
      "item5": "1999",
      "content5": "Reached 100% machine utilization and one million (without loss work) safety hours.",
      "item6": "2000",
      "content6": "Continued to maintain 100% full capacity running and received the best company award on environmental protection from Ministry of Economic Affairs.",
      "item7": "2001",
      "content7": "Won the recognition of the best company on successful quality improvement case from the Ministry of Economic Affairs. Reached 1.5-million safety hours. Became ISO 9001 certificated company.",
      "item8": "2002",
      "content8": "Won the recognition of the best company on successful quality improvement case from the Ministry of Economic Affairs for the consecutive second year.",
      "item9": "2003",
      "content9": "Reached 2-million safety hours.",
      "item10": "2004",
      "content10": "Passed DuPont 2nd party audit of Process Safety Management (PSM) system.   Renamed DuPont Far Eastern Co., Ltd. as INVISTA-Far Eastern Co., Ltd. since DuPont spun off Nylon business to be a legal entity INVISTA.",
      "item11": "2006",
      "content11": "Reached 3-million safety hours.  Won the superior result award of workplace health enhancement from Taoyuan County Government.",
      "item12": "2007",
      "content12": "Received EHS (Environmental, Health and Safety) Excellence Award from INVISTA Global.",
      "item13": "2008",
      "content13": "Renamed INVISTA-Far Eastern Co., Ltd. as Far Eastern Fibertech Co., Ltd. and became a wholly owned subsidiary of Far Eastern Group.",
      "item14": "2009",
      "content14": "Passed certification of BSI (British Standards Institution) GHG (Green House Gases) Emission Verification. Reached 4-million safety hours.",
      "item15": "2012",
      "content15": "Won the zero LWC safety hours record achieved medal by CLA. Reached 5-million safety hours. Passed LRQA® OHSAS 18001 and TOSHMS(CNS15506) certification.",
      "item16": "2013",
      "content16": "Reached 5.5 millions safety hours. Passed certification of Oeko-Tex<sup>®</sup> Standard 100.",
      "item17": "2015",
      "content17": "FEFC<sup>®</sup>, the Nylon 6,6 Recycled yarn, has passed 'Recycled Material Verified' from TUV Rheinland, under certified product of 'Pre-consumer Recycled Nylon Yarn'.",
      "item18": "2016",
      "content18": "Completed Greenhouse gas emissions auditing for year 2015, carbon emission per tons has reduced over 30% compared to 2006",
      "item19": "2017",
      "content19": "FEFC<sup>®</sup> eco, the Nylon 6,6 Recycled yarn, has been inspected and assessed by the Control Union Certifications to the Global Recycled Standard (GRS) version 3.0, under certified yarns for both FDY and POY, contains 92% pre-consumer recycled Nylon.",
      "item20": "2018",
      "content20": "Reached 8 millions safety hours. FEFC<sup>®</sup>eco, the Nylon 6,6 Recycled yarn, has been inspected and assessed by the Control Union Certifications to the Global Recycled Standard (GRS) version 3.0, under certified yarns for both FDY and POY, contains 92%、100% pre-consumer recycled Nylon.",
      "item21": "2020",
      "content21": "9 million (non-destructive) safe working hours will be reached",
      "item22": "2020",
      "content22": "ISO45001 (Occupational Safety and Health Management System) passed the certification of Lloyd's Corporation.",
      "item23": "2021",
      "content23": " FEFC<sup>®</sup> eco passed IDFL (International Down and Feather Testing Laboratory) Global Recycle Standard (GRS) version 4.0, Contain 100% of Pre-consumer Recycle Nylon Yarn (FEFC<sup>®</sup> eco  contains 100% recycled yarn)。",
      "item24": "2021",
      "content24": "FEFC<sup>®</sup> eco  passed the Swiss Textile Testing Center (TESTEX) OEKO-TEX Standard 100 Class 1"
    },
    "page4": {
      "title": "FEFC SHE Policy",
      "content1": "&emsp;&emsp;Far Eastern Fibertech Company Limited (FEFC) is a subsidiary of the Far Eastern Group. It was established in 1995. FEFC offers high-quality specialty and value-added Nylon 6,6 fibers for textile industrial through a world-class manufacturing facility and most advance technology.",
      "content2": " &emsp;&emsp;FEFC will manage operations and produce, distribute and market products in a manner that protects the Safety, Health and Environment (SHE) of employees, customers, contractors and the public. Through employee’s consultation and participation of SHE matters and activities to continuously improve management measures of people, facility and manufacturing process, so as to enhance safety performance, reduce and prevent injury & illness, promote employee health, and fully comply with laws and regulations, toward a goal of “zero incident”.",
      "item3": "Following are FEFC’s commitment to Safety, Health and Environment management system:",
      "content3": "<li class=\"mb-2\"><span class=\"font-weight-bold\">Consultation and participation: </span><div>Safety, Health, and Environment are each individual’s responsibilities. Through employee’s consultation and participation to get involved in SHE matters and activities to ensure each individual takes the responsibilities for safety, health and environment.</div></li><li class=\"mb-2\"><span class=\"font-weight-bold\">Continuously Improvement: </span><div>Ensure continuous improvement through daily observation and executing corrective measures to build a friendly workplace of safety, health and environment.</div></li><li class=\"mb-2\"><span class=\"font-weight-bold\">Incident Prevention: </span><div>All incidents are preventable. No matter on handling facilities, people work, dealing with hazardous materials or managing process hazards and change, to prevent occupational injury, illness and environmental incidents is our highest priority.</div></li><li class=\"mb-2\"><span class=\"font-weight-bold\">Health Promotion: </span><div>Aggressively promote the concepts of healthy lifestyle to all employees and encourage them to manage their own physical and mental health and foster positive attitude of health and habit as well.</div></li><li class=\"mb-2\"><span class=\"font-weight-bold\">Regulation Compliance：</span><div>Be committed in fully complying with all laws, regulations, and other requirements with regard to safety, health and environment.</div></li>"
    },
    "page5": {
      "title": "Best Practice Principles of Ethical Corporate Management",
      "rule": {
        "item1": "1.Purpose of establishment, entities:",
        "itemContent1": "The purpose of the Best Practice Principles of Ethical Corporate Management (hereinafter referred to as the “Principles”) is to cultivate an enterprise culture for Far Eastern Fibertech Co., Ltd. (hereinafter referred to as the “Company”) to sustain the development of the Company and ensure all businesses conducted with sincerity and integrity.",
        "item2": "2.Scope of implementation:",
        "itemContent2": "The Principles shall be abided by directors, managers, employees, mandataries, and other entities with actual ability to control the Company (hereinafter referred to as the “Actual Controllers”). The aforementioned individuals and entities hereinafter are referred collectively to as the“Company Professionals”.",
        "item3": "3.Content:",
        "itemContent3": {
          "list1": "3.1 Prohibition against business conduct without prudence and integrity",
          "content1": "<ul><li>Company Professionals are prohibited from, directly or indirectly, offering, promising to offer, requesting, or receiving improper benefits of any sort when conducting business with counterparties. Obtaining or sustaining benefits by conducting business without sincerity and integrity, in any illegal way, or in breach of fiduciary duty (hereinafter referred to as “Misconducts”) is also prohibited.</li><li>The counterparties mentioned in the preceding paragraph includes public servants, political campaign candidates, political parties and members and employees of political parties, and all other state-owned or private-owned enterprises or organizations and their directors, supervisors, managers, employees, Actual Controllers, and other interested persons/entities.</li></ul>",
          "list2": "3.2 Definitions and scopes of benefits",
          "content2": "Benefits” mentioned in the Principles are referred to as anything worth of a value, including but not limited to monetary payments, endowments, commissions, job positions, services, preferential treatments, kickbacks and so forth in any name or any mean. Those are perceived as normal social activity, occurs occasionally, and have no impact or have no concern to impact specific legal rights and obligations may be excluded.",
          "list3": "3.3 Compliance with laws and regulations",
          "content3": "The first premise of conducting business with sincerity and integrity is that the Company shall comply with the Company Act, Securities and Exchange Act, Business Accounting Act, Political Donation Act, Anti-corruption Statue, Government Procurement Law, Act on Recusal of Public Servants Due to Conflicts of Interest, regulations governing security listings and all other business activities.",
          "list4": "3.4 Policy",
          "content4": "With the managerial philosophy of anti-corruption, transparency, and responsibility, the policy of the Company is to ensure all business being conducted with sincerity and integrity. The Company shall also build a reliable mechanism for corporate governance, risk control, and risk management, in hopes of creating a business environment for sustainable development.",
          "list5": "3.5 Prevention procedures and rules",
          "content5": "In accordance with the Principles, the Company shall refer to prevailing domestic and foreign standards or guidelines in establishing procedures and rules including operational procedures, guidelines and training, to prevent Company Professionals from conducting business without prudence and integrity; meanwhile, specifically identify what Company Professionals must pay attention to while conducting business.",
          "list6": "3.6 Commitment and execution",
          "content6": "<div class=\"pl-3\"></div><ul><li>The Company shall request their directors and senior management to issue a statement of compliance with the ethical management policy and require in the terms of employment that employees comply with such policy.</li><li>The policy of ethical corporate management with sincerity and integrity shall be disclosed explicitly in the website of the Company. Board of Directors and the senior management shall have strong and rigorous commitment to the execution of such policy, and enforce the policy to the internal management and external commercial activities.</li></ul>",
          "list7": "3.7 Prohibition against infringing intellectual property rights",
          "content7": "Company Professionals shall comply with applicable laws and regulations, the Company's internal    operational procedures, and contractual provisions concerning intellectual property, and may not use, disclose, dispose, or damage intellectual property or otherwise infringe intellectual property rights, such as management secrets, trademarks, patents, or publications, without the prior consent of the intellectual property rights holder.",
          "list8": "3.8 Ethical commercial activities and prohibition against acts of unfair competition",
          "content8": "<ul><li>The Company shall act with integrity and fairness when engaging in a commercial activity with counterparty in accordance with applicable competition laws and regulations, and may not fix prices, make rigged bids, establish output restrictions or quotas, or share or divide markets by allocating customers, suppliers, territories, or lines of commerce</li><li>Prior to engaging in a commercial transaction, the Company shall take into consideration the legitimacy and legality of the counterparty such as agents, vendors, customers, and other entities, and their Misconduct record, if any. The Company shall avoid engaging in business with counterparty with any record of Misconducts.</li><li>When entering into material contracts with counterparties, the Company shall include provisions in such contracts demanding the compliance of ethical corporate management policy. And such contracts shall also include clauses to terminate or cancel the contracts at any time by the Company, if Misconducts are performed, or suspected of being performed, by the counterparties.</li></ul>",
          "list9": "3.9 Prohibition against offering and receiving bribery",
          "content9": "When conducting business, Company Professionals shall not directly or indirectly offer, promise to offer, request or accept any improper Benefits, including bribery, kickbacks, commissions, grease payments, or offer or accept improper Benefits in any way and any name to or from clients, agents, contractors, suppliers, public servants, or other interested parties, unless the laws of the territories where the companies operate permit so.",
          "list10": "3.10 Prohibition against illegal political donations",
          "content10": "When directly or indirectly providing a donation to political parties or organizations or individuals participating in political activities, Company Professionals shall comply with the Political Donations Act and internal relevant procedures of the Company, and shall not make such donations in exchange for commercial gains or business advantages.",
          "list11": "3.11 Prohibition against improper charity donations and sponsorship",
          "content11": "When making or offering charity donations and sponsorship, Company Professionals shall comply with relevant laws, regulations, and internal procedures of the Company, and shall not surreptitiously engage in bribery.",
          "list12": "3.12 Prohibition against improper gifts, hospitality, or other improper Benefits",
          "content12": "Directors, managers, and all other employees of the Company, shall not directly or indirectly offer or accept any unreasonable presents, hospitality or other improper benefits to establish business relationship or influence commercial transactions.",
          "list13": "3.13 Confidentiality agreement",
          "content13": "<ul><li>Company Professionals shall comply with the Securities and Exchange Act, may not use undisclosed information to engage insider trading or disclose the information to others to engage insider trading.</li><li>Any institution or personnel take part in the Company’s merger, segmentation, share purchasing or transferring, important memorandum, strategic alliance, cooperation plan or important contract, shall sign a confidentiality agreement with the Company, promise not to disclose the Company’s business secrets or other important information, and may not use the information without the prior consent of the Company.</li></ul>",
          "list14": "3.14 Organization and responsibility",
          "content14": "<ul><li>Company Professionals shall exercise the due care of good administrators to urge the Company to prevent Misconducts, review the results of the preventive procedures at any time, and continually make adjustments so as to ensure thorough implementation of the ethical corporate management policies.</li><li>To fulfill the best practices of the ethical corporate management, the Chief Corporate Governance Officer under the Board of Directors and Human Resources Department of the Company is dedicated to be in charge of establishing and enforcing the ethical corporate management policies and prevention procedures, and shall report to the Board of Directors on a regular basis (at least once a year).</li></ul>",
          "list15": "3.15 Compliance with laws and regulations",
          "content15": "<ul><li>Company Professionals shall comply with laws, regulations, and the prevention procedures of the Company when conducting business. </li><li>Company Professionals shall abide by all articles, rules, bylaws, operation procedures of the Company, and follow the orders from authorized superior management.</li></ul>",
          "list16": "3.16 Prevention of conflicts of interests",
          "content16": "<ul><li>The Company shall promulgate policies for preventing conflicts of interests and offer appropriate means for Company Professionals to proactively explain if their interests would potentially conflict with those of the Company.</li><li>Directors of the Company shall exercise a high degree of self-discipline. A director may present his/her opinion and answer relevant questions but is prohibited from participating in discussion of or voting on any proposal where the director or the juristic person that the director represents is an interested party, and such participation is likely to prejudice the interests of the Company; neither shall a director vote on such proposal as a proxy of another director in such circumstances. The directors shall practice self-discipline and must not support one another in improper manner.</li><li>Company Professionals shall not take advantage of their positions in the Company to obtain improper Benefits for themselves, their spouses, parents, children or any other person</li></ul>",
          "list17": "3.17 Accounting and internal control",
          "content17": "<ul><li>For business activities which may be more likely than normal to be involved in Misconducts, the Company shall establish an effective accounting system and an internal control system, not have off-the-book accounts or keep secret accounts, and shall review the systems regularly so as to ensure that the design and enforcement of the systems are showing results.</li><li>Internal auditors of the Company shall periodically examine the results of compliance with the foregoing, and prepare audit reports submitted to the Board of Directors.</li></ul>",
          "list18": "3.18 Educational training and performance evaluating system",
          "content18": "<ul><li>The Company shall periodically organize training programs for Company Professionals, so that business departments shall be able to make the commitment, policy, prevention procedures of the Company to implement the Principles, and consequences of violating the Principles be informed and fully understood by their transaction counterparties.</li><li>The management of the Company shall at all times evaluate the educational proficiencies, behaviors, capabilities, compliance with the Principles, and working performance of subordinates, and the evaluation records shall be incorporated into the annual performance appraisal system.</li></ul>",
          "list19": "3.19 Blow-the-whistle, discipline and appeal system",
          "content19": "<ul><li>For any violation of the Principles being found, Company Professionals shall proactively report to the Audit Committee, the department heads, head of internal audit, the Chief Corporate Governance Officer, Human Resources Department, and other appropriate authorized managers. The Company shall strictly preserve the identity of the whistle-blower and the content of the report.</li><li>The Company tolerates no violation. Any of the Company Professionals obtains, or intends to obtain, improper Benefits for oneself or others at the cost of the Company by using one’s position and authority shall be dismissed, and unconditionally indemnify the Company for all losses, if occurred.</li><li>Any of the Company Professionals found to be in violation of the Principles shall be disciplined in accordance with the reward and disciplinary rules of the Company. Those who as a result of violation are dismissed by the Company will no longer be employed again by the Company or its affiliates. Where necessary, a case shall be reported to the competent authority or referred to the judicial authority.</li><li>The Procedures for Ethical Management and Guidelines for Conduct is established in the Company to guide Company Professionals how to conduct business.</li></ul>",
          "list20": "3.20 Review and amendments of the Principles",
          "content20": "The Company shall at all times monitor the development of relevant local and international  regulations concerning ethical corporate management, and Company Professionals to make recommendations so as to review and improve the Principles and achieve better results from implementing the Principles.",
          "list21": "3.21 Implementation",
          "content21": "After receiving the approval from the CEO, the Principles will be implemented. The amendments of the Guidelines follow the same procedure."
        }
      }
    },
    "page6": {
      "title": "FEFC Values",
      "content": "We, FEFC Leaders, are committed to promoting FEFC Values to build a world class operation through our Felt Leadership in managing FEFC business.",
      "list": {
        "item1": "Sincerity, Diligence, Thrift, Prudence",
        "content1": "Apply Far Eastern Group's founding spirits as guiding principles of FEFC business management.",
        "item2": "EHS & Compliance",
        "content2": "Conduct all affairs lawfully and ethically. Strive for excellence in environmental, safety and compliance in all areas of business operations.",
        "item3": "Innovation & Quality",
        "content3": "Create and deliver superior values that meet or exceed customer requirements and strengthen FEFC long term competitiveness.",
        "item4": "Execution",
        "content4": "Develop vision and organization culture that is business-focused & results-oriented with clear accountability, discipline and fulfillment of commitment.",
        "item5": "Passion & Insist-on-Excellence",
        "content5": "Demonstrate strong ownership and proactiveness, and drive continuous improvement to achieve high standards in every thing we do.",
        "item6": "Speed & Flexibility",
        "content6": "Lead and capitalize on changes and strengthen sense of urgency to cope with the fast changing business environment.",
        "item7": "Valuing People & Team Spirit",
        "content7": "Treat people with dignity, respect, and sensitivity. Realize individual's full potential by appreciating the value of diversity. Establish a role model of networking that completely breaks boundaries and cuts bureaucracy in the organization.",
        "item8": "Learn and Teach",
        "content8": "Build continuous learning and growing culture to strengthen organization capability in FEFC."
      }
    }
  },
  "ContactUs": {
    "title": "Contact Us",
    "item1": "Sales",
    "item2": "Recruiting"
  },
  "Hr": {
    "title": "Recruiting",
    "content1": "Sincere invitation",
    "content2": "Join us with your full of enthusiasm and ambition",
    "content3": "",
    "content4": "Job opening",
    "item1": "Talent development",
    "itemContent1": "The company provides diversified learning channels and training resources to cultivate professional knowledge, skills and an optimistic and enterprising attitude needed in the workplace to enhance working performance and operational results.",
    "item2": "Talent development system and structure",
    "itemContent2": "A complete and systematic function development and learning plan allows employees to develop the required abilities at all stages. Employees’ self-development goals are achieved; future leaders and experts are developed in the talent development system with resources from Far Eastern group's talent development center and industry-university cooperation plan.",
    "list": "<li class=\"mb-1\"><strong>New employee orientation: </strong>In order to enable new employee adapt to organizational culture and integrate into the company quickly. Lecturers from different department are assigned to guide and assistant new employee.</li><li class=\"mb-1\"><strong>Functional skill training: </strong> An annual functional skill training development plan cultivate the employees with job required skills and licenses to perform different tasks in different functions.</li><li class=\"mb-1\"><strong>Trainee development plan: </strong>The future leaders are guided and developed through a trainee development plan with many professional lectures from different functions and a series of training courses.</li><li class=\"mb-1\"><strong>First line supervisor development plan: </strong>First line supervisors learn together to establish strong chemistry and good team work. Lectures in various fields guide supervisors to develop management and leadership skills.</li><li class=\"mb-1\"><strong>Industry-University Cooperation Program: </strong>Participate in the national industry-university cooperation program, provide an internship environment for students, create a win-win cooperation model for industry and academia, fulfill corporate social responsibilities, and cultivate future talents for the country.</li><li class=\"mb-1\"><strong>Far Eastern group Talent Cultivation Plan: </strong>Many talents from different business are developed with outstanding professional and leadership skills through this training plan. These talents enhance company's and industry's competitiveness.</li>"
  },
  "Tactel": {
    "title": "TACTEL/SUPPLEX",
    "item1": "TACTEL<sup>®</sup> fabric",
    "content1": "Soft. Lift. Surprisingly strong. TACTEL<sup>®</sup> fabric creates garments that are soft, supplely smooth, breathable and lightweight. Because of its soft and light-weight nature, TACTEL<sup>®</sup> fabric is widely used in women’s intimate apparel. TACTEL<sup>®</sup> fabric are also used in a vast collection of men’s, women’s and children’s clothing and even accessories.",
    "item2": "SUPPLEX<sup>®</sup> fabric",
    "content2": "SUPPLEX<sup>®</sup> fabric offers consumers a feel similar to cotton with the benefits of advanced fiber technology. Cotton’s natural texture and aesthetics make it the fabric of choice in many garments categories. Garments make with cotton can sometimes crease and shrink easily and they often fade in color. SUPPLEX<sup>®</sup> fabric – a family that combines the traditional apparel of cotton with the performance benefit of modern fiber technology. SUPPLEX<sup>®</sup> fabrics are breathable, hold their shape, dry faster than cotton and retain their color.",
    "content3": "TACTEL<sup>®</sup> and SUPPLEX<sup>®</sup> are trademarks of The LYCRA Company and are used under license to FEFC for its Nylon 6,6 products."
  },
  "items": [
    {
      "title": "Green Eco",
      "imageUrl": "greeneco.png",
      "url": "/greeneco",
      "detailTextArray": ["Recycle", "Solution dye", "DWR"]
    },
    {
      "title": "Comfort Stretch",
      "imageUrl": "comfort.png",
      "url": "/comfort",
      "detailTextArray": ["Comfort ", "Stretch"]
    },
    {
      "title": "Super Hand Feel",
      "imageUrl": "superhand.png",
      "url": "/superhand",
      "detailTextArray": ["Super tactile", "Super experience"]
    },
    {
      "title": "Style & Looks",
      "imageUrl": "style.png",
      "url": "/style",
      "detailTextArray": ["Style ", "Looks"]
    },
    {
      "title": "Functions",
      "imageUrl": "functions.png",
      "url": "/functions",
      "detailTextArray": ["Function", "Use"]
    },
    {
      "title": "Strength & Durability",
      "imageUrl": "strength.png",
      "url": "/strength",
      "detailTextArray": ["Strength", "Durability"]
    }
  ],
  "More": "More...",
  "Aboutlist": "About",
  "CompanyProfile": "FEFC",
  "personnel_recruitment": "Recruiting",
  "Contact_us": "Contact Us",
  "Product_Information": "Product"
}
