<template>
  <nav
    class="navbar fixed-top navbar-light bg-main border-bottom border-primary"
  >
    <a class="navbar-brand" href="#">
      <img src="./assets/logo.png" alt="" />
    </a>
    <div class="form-inline ml-auto">
      <small class="my-auto" style="font-size: 0.75rem">
        {{ $t("changeLocale") }}</small
      >
      <button
        @click="$i18n.locale = 'en'"
        class="btn btn btn-link"
        :class="{
          'text-black-50': showDarkText,
          'text-white': !showDarkText,
        }"
        type="button"
        style="font-size: 0.75rem"
      >
        Engish
      </button>
      <button
        @click="$i18n.locale = 'cn'"
        class="btn btn-link"
        :class="{
          'text-black-50': showDarkText,
          'text-white': !showDarkText,
        }"
        type="button"
        style="font-size: 0.75rem"
      >
        中文
      </button>
    </div>
  </nav>
  <router-view />
  <div class="container-fluid px-0 bg-primary text-light">
    <div class="container py-3 p-md-5">
      <div class="row">
        <div class="col-12">
          <div
            class="
              d-flex
              flex-wrap
              h-100
              justify-content-start
              align-items-start
            "
          >
            <div class="col-12 col-md-auto d-flex flex-column">
              <a
                href="https://www.feg.com.tw/"
                class=""
                target="_blank"
                rel="noopener noreferrer"
                ><img src="@/assets/fe.png" alt="" srcset=""
              /></a>
              <div class="py-2"></div>
            </div>

            <div class="ml-md-auto d-flex flex-column">
              <span
                class="text-center pt-1 pb-2"
                style="color: #aaa; font-size: 0.9rem"
                >{{ $t("Aboutlist") }}</span
              >
              <router-link
                to="/about"
                name=""
                class="btn btn-outline-light border-0"
                role="button"
                >{{ $t("CompanyProfile") }}</router-link
              >
              <router-link
                to="/hr"
                name=""
                class="btn btn-outline-light border-0"
                role="button"
                >{{ $t("personnel_recruitment") }}</router-link
              >
              <router-link
                to="/contactus"
                name=""
                class="btn btn-outline-light border-0"
                role="button"
                >{{ $t("Contact_us") }}</router-link
              >
            </div>
            <div class="mx-3 d-flex flex-column">
              <span
                class="text-center pt-1 pb-2"
                style="color: #aaa; font-size: 0.9rem"
                >{{ $t("Product_Information") }}</span
              >

              <router-link
                to="/tactel"
                name=""
                class="btn btn-outline-light border-0"
                role="button"
                >TACTEL<sup>®</sup><br />
                SUPPLEX<sup>®</sup></router-link
              >
              <a
                href="/dm/2019-FEFC-n66-v2.pdf"
                download="2019-FEFC-n66-v2.pdf"
                name=""
                class="btn btn-outline-light border-0"
                role="button"
                >DM</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="py-2 d-flex justify-content-end" style="font-size: 0.75rem">
        <div>
          Copyright<sup>®</sup> {{ new Date().getFullYear() }} Far Eastern
          Fibertech Co., Ltd. Allright reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup(props) {
    const store = useStore();
    return {
      showDarkText: computed(
        () => [0, 3].indexOf(store.getters.pageIndex) > -1
      ),
      pageIndex: computed(() => store.getters.pageIndex),
    };
  },
  methods: {},
};
</script>
